
export default[
  {
    path: '/profil_kelompok',
    name: '/profil_kelompok',
    component: () => import('@/views/profil_kelompok/Index.vue'),
    children: [{
      path: '/profil_kelompok/anggota/',
      name: 'profil_kelompok_anggota',
      component: () => import('@/views/profil_kelompok/anggota/Index.vue'),
      meta: {
        pageTitle: 'Profil kelompok',
        breadcrumb: [ 
          {
            text: 'Profl Kelompok',
            active: true,
          },
          {
            text: 'Anggota',
            active: true,
          },
        ],
      }
    }],
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [ 
        {
          text: 'Profile',
          active: true,
        },
      ],
    },
  }, 
  {
    path: '/pengajuan_stdk',
    name: '/pengajuan_stdk',
    component: () => import('@/views/pengajuan_stdk/Index.vue'),
    meta: {
      pageTitle: 'Pengajuan STDK',
      breadcrumb: [ 
        {
          text: 'Surat Tanda Daftar Kelompok',
          active: true,
        },
      ],
    },
  },  
  {
    path: '/pendataan_bantuan',
    name: '/pendataan_bantuan',
    component: () => import('@/views/pendataan_bantuan/Index.vue'), 
    meta: {
      pageTitle: 'Pendataan Bantuan',
      breadcrumb: [ 
        {
          text: 'Pendataan Bantuan Kelompok Periakan',
          active: true,
        },
      ],
    },
  },  

  {
    path: '/pengajuan_bantuan',
    name: '/pengajuan_bantuan',
    component: () => import('@/views/pengajuan_bantuan/Index.vue'),
    children: [
      {
        path: '/pengajuan_bantuan/home',
        name: '/pengajuan_bantuan/home',
        component: () => import('@/views/pengajuan_bantuan/Home.vue'),
        meta: {
          pageTitle: 'Pengajuan Bantuan ',
          breadcrumb: [ 
            {
              text: 'Akujan Bantuan',
              active: true,
            }, 
          ],
        }
      },
      {
        path: '/pengajuan_bantuan/ajukan',
        name: '/pengajuan_bantuan/ajukan',
        component: () => import('@/views/pengajuan_bantuan/ajukan/Index.vue'),
        meta: {
          pageTitle: 'Pengajuan Bantuan ',
          breadcrumb: [ 
            {
              text: 'Akujan Bantuan',
              active: true,
            }, 
          ],
        }
      }, {
      path: '/pengajuan_bantuan/detail',
      name: '/pengajuan_bantuan/detail',
      component: () => import('@/views/pengajuan_bantuan/detail_ajuan/Index.vue'),
      meta: {
        pageTitle: 'Detail Pengajuan',
        breadcrumb: [ 
          {
            text: 'Detail Pengajuan Bantuan',
            active: true,
          },
          {
            text: 'Detail Pengajuan Bantuan',
            active: true,
          },
        ],
      }
    }],
    meta: {
      pageTitle: 'Pengajuan Bantuan Kelompok Periakan',
      breadcrumb: [ 
        {
          text: 'Pengajuan Bantuan Kelompok Periakan',
          active: true,
        },
      ],
    },
  }, 


]
// /validasi_profil_usaha
// /validasi_rekom_bbm
// /edit_profil_kelompok/list
// /profil_usaha/list'

export default [{
        path: '/validasi_profil_usaha',
        name: '/validasi_profil_usaha',
        component: () => import('@/views/rekom/validasi/Index.vue'),
        children: [{
                path: '/validasi_profil_usaha/list',
                name: '/validasi_profil_usaha/list',
                component: () => import('@/views/rekom/validasi/component/Index.vue'),
                meta: {
                    pageTitle: 'Validasi Profil Usaha',
                    breadcrumb: [{
                        text: 'Validasi Profil Usaha',
                        active: true,
                    }, ],
                }
            },
            {
                path: '/validasi_profil_usaha/profil_usaha',
                name: '/validasi_profil_usaha/profil_usaha',
                component: () => import('@/views/rekom/validasi/component/Profil_usaha.vue'),
                meta: {
                    pageTitle: 'Validasi Profil Usaha',
                    breadcrumb: [{
                        text: 'Validasi Profil Usaha',
                        active: true,
                    }, ],
                }
            }
        ],
        meta: {
            pageTitle: 'Validasi Profil Usaha',
            breadcrumb: [{
                text: 'Validasi Profil Usaha',
                active: true,
            }, ],
        },
    },
    {
        path: '/validasi_rekom_bbm',
        name: '/validasi_rekom_bbm',
        component: () => import('@/views/rekom/rekom_bbm/Index.vue'),
        children: [{
                path: '/validasi_rekom_bbm/list',
                name: '/validasi_rekom_bbm/list',
                component: () => import('@/views/rekom/rekom_bbm/component/Index.vue'),
                meta: {
                    pageTitle: 'Validasi Pengajuan Rekom BBM',
                    breadcrumb: [{
                        text: 'Validasi Pengajuan Rekom BBM',
                        active: true,
                    }, ],
                }
            },
            {
                path: '/validasi_rekom_bbm/profil_usaha',
                name: '/validasi_rekom_bbm/profil_usaha',
                component: () => import('@/views/rekom/rekom_bbm/component/Profil_usaha.vue'),
                meta: {
                    pageTitle: 'Validasi Pengajuan Rekom BBM',
                    breadcrumb: [{
                        text: 'Validasi Pengajuan Rekom BBM',
                        active: true,
                    }, ],
                }
            }
        ],
        meta: {
            pageTitle: 'Validasi Pengajuan Rekom BBM',
            breadcrumb: [{
                text: 'Validasi Pengajuan Rekom BBM',
                active: true,
            }, ],
        },
    },
    {
        path: '/profil_usaha',
        name: '/profil_usaha',
        component: () => import('@/views/rekom/profil_usaha/Index.vue'),
        children: [{
                path: '/profil_usaha/list',
                name: '/profil_usaha/list',
                component: () => import('@/views/rekom/profil_usaha/component/Index.vue'),
                meta: {
                    pageTitle: 'Kelola profil Usaha',
                    breadcrumb: [{
                        text: 'Kelola Profil Usaha',
                        active: true,
                    }, ],
                }
            },
            {
                path: '/profil_usaha/profil_usaha',
                name: '/profil_usaha/profil_usaha',
                component: () => import('@/views/rekom/profil_usaha/component/Profil_usaha.vue'),
                meta: {
                    pageTitle: 'Edit profil Usaha',
                    breadcrumb: [{
                        text: 'Edit Profil Usaha',
                        active: true,
                    }, ],
                }
            }
        ],
        meta: {
            pageTitle: 'Kelola Profil Usaha',
            breadcrumb: [{
                text: 'Kelola Profil Usaha',
                active: true,
            }, ],
        },
    },


]
import Vue from 'vue'
import VueRouter from 'vue-router'
import acl from './routers/acl'
import error from './routers/error'
import home from './routers/home'
import profile from './routers/profile'
import registrasi from './routers/registrasi'
import profil_kelompok from './routers/profil_kelompok'
import dinas from './routers/dinas'
import laporan from './routers/laporan'
import user_mandiri from './routers/user_mandiri'
import rekom from './routers/rekom';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'login' } },
    ...acl,
    ...error,
    ...home,
    ...profile,
    ...registrasi,
    ...profil_kelompok,
    ...dinas,
    ...laporan,
    ...user_mandiri,
    ...rekom
  ],
}) 
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})



export default router

export default {
    mamespaced : true,
    state: { 
        rsIdKelompok :"",
        rsValidasi: [], 
        rsNote : [],
        rsAllNote : []
    },
    mutations: { 
        setRsValidasi(state, value) {
            state.rsValidasi = value 
        }, 
        setRsNote(state, value) {
            state.rsNote = value 
        }, 
        SetRsAllNote(state, value) {
            state.rsAllNote = value 
        },
        setIdKelompok(state, value) {
            state.rsIdKelompok = value 
        },  
    },
    actions: {
   },
   
}

export default[
    
    {
      path: '/laporan_profil_pelaku_usaha',
      name: '/laporan_profil_pelaku_usaha',
      component: () => import('@/views/laporan/profil_pelaku_usaha/Index.vue'),
      meta: {
        pageTitle: 'Laporan',
        breadcrumb: [ 
          {
            text: 'Data Profil Pelaku Usaha',
            active: true,
          },
        ],
      },
    },
    {
      path: '/laporan_penerima_bantuan',
      name: '/laporan_penerima_bantuan',
      component: () => import('@/views/laporan/penerimabantuan/Index.vue'),
      meta: {
        pageTitle: 'Laporan ',
        breadcrumb: [
          {
            text: 'Penerima Bantuan',
            active: true,
          },
        ],
      },
    },
    {
      path: '/laporan_data_pelaku_usaha',
      name: '/laporan_data_pelaku_usaha',
      component: () => import('@/views/laporan/pelaku_usaha/Index.vue'),
      meta: {
        pageTitle: 'Laporan',
        breadcrumb: [
          {
            text: 'Download Semua Data Pelaku Usaha',
            active: true,
          },
        ],
      },
    },
    {
      path: '/laporan_stdk',
      name: '/laporan_stdk',
      component: () => import('@/views/laporan/stdk/Index.vue'),
      meta: {
        pageTitle: 'Laporan',
        breadcrumb: [
          {
            text: 'Download File STDK',
            active: true,
          },
        ],
      },
    },  
]
export default[ 
    {
      path: '/validasi_dokumen',
      name: '/validasi_dokumen',
      component: () => import('@/views/dinas/validasi/Index.vue'),
      children: [
        {
          path: '/validasi_dokumen/list',
          name: '/validasi_dokumen/list',
          component: () => import('@/views/dinas/validasi/component/Card.vue'),
          meta: {
            pageTitle: 'Validasi Dokumen',
            breadcrumb: [ 
              {
                text: 'Validasi Dokumen',
                active: true,
              }, 
            ],
          }
        }, {
        path: '/validasi_dokumen/detail',
        name: '/validasi_dokumen/detail',
        component: () => import('@/views/dinas/validasi/detail_kelompok/Index.vue'),
        meta: {
          pageTitle: 'Vlidasi Dokumen Profil Kelompok',
          breadcrumb: [ 
            {
              text: 'Validasi Dokumen ',
              active: true,
            },
            {
              text: 'Profil Kelolpok',
              active: true,
            },
          ],
        }
      }],
      meta: {
        pageTitle: 'Dinas BP3 Tegal',
        breadcrumb: [ 
          {
            text: 'Validasi Dokumen',
            active: true,
          },
        ],
      },
    }, 

    {
      path: '/validasi_stdk',
      name: '/validasi_stdk',
      component: () => import('@/views/dinas/validasi_pengajuan_stdk/Index.vue'),
      children: [
        {
          path: '/validasi_stdk/list',
          name: '/validasi_stdk/list',
          component: () => import('@/views/dinas/validasi_pengajuan_stdk/component/Card.vue'),
          meta: {
            pageTitle: 'Validasi STDK',
            breadcrumb: [ 
              {
                text: 'Validasi STDK',
                active: true,
              }, 
            ],
          }
        }, {
        path: '/validasi_stdk/detail',
        name: '/validasi_stdk/detail',
        component: () => import('@/views/dinas/validasi_pengajuan_stdk/detail_kelompok/Index.vue'),
        meta: {
          pageTitle: 'Vlidasi Dokumen Profil Kelompok',
          breadcrumb: [ 
            {
              text: 'Validasi STDK ',
              active: true,
            },
            {
              text: 'Detail Pengajuan STDK',
              active: true,
            },
          ],
        }
      }],
      meta: {
        pageTitle: 'Dinas BP3 Tegal',
        breadcrumb: [ 
          {
            text: 'Validasi Pengajuan STDK',
            active: true,
          },
        ],
      },
    }, 

    {
      path: '/validasi_stdk_ka_din',
      name: '/validasi_stdk_ka_din',
      component: () => import('@/views/dinas/validasi_pengajuan_stdk_kadin/Index.vue'),
      children: [
        {
          path: '/validasi_stdk_ka_din/list',
          name: '/validasi_stdk_ka_din/list',
          component: () => import('@/views/dinas/validasi_pengajuan_stdk_kadin/component/Card.vue'),
          meta: {
            pageTitle: 'Validasi STDK',
            breadcrumb: [ 
              {
                text: 'Validasi STDK',
                active: true,
              }, 
            ],
          }
        }, {
        path: '/validasi_stdk_ka_din/detail',
        name: '/validasi_stdk_ka_din/detail',
        component: () => import('@/views/dinas/validasi_pengajuan_stdk_kadin/detail_kelompok/Index.vue'),
        meta: {
          pageTitle: 'Vlidasi Dokumen Profil Kelompok',
          breadcrumb: [ 
            {
              text: 'Validasi STDK Kepala Dinas ',
              active: true,
            },
            {
              text: 'Detail Pengajuan STDK',
              active: true,
            },
          ],
        }
      }],
      meta: {
        pageTitle: 'Dinas BP3 Tegal',
        breadcrumb: [ 
          {
            text: 'Validasi Pengajuan STDK Kepala Dinas',
            active: true,
          },
        ],
      },
    }, 
    {
      path: '/edit_profil_kelompok',
      name: '/edit_profil_kelompok',
      component: () => import('@/views/dinas/edit_profil_kelompok/Index.vue'),
      children: [
        {
          path: '/edit_profil_kelompok/list',
          name: '/edit_profil_kelompok/list',
          component: () => import('@/views/dinas/edit_profil_kelompok/List.vue'),
          meta: {
            pageTitle: 'Edit Profil Kelompok',
            breadcrumb: [ 
              {
                text: 'Edit Profil Kelompok',
                active: true,
              }, 
            ],
          }
        }, {
        path: '/edit_profil_kelompok/detail',
        name: '/edit_profil_kelompok/detail',
        component: () => import('@/views/dinas/edit_profil_kelompok/detail_kelompok/Index.vue'),
        meta: {
          pageTitle: 'Edit Profil Kemompok',
          breadcrumb: [ 
            {
              text: 'Edit Profil Kelompok ',
              active: true,
            },
            {
              text: 'Detail Profil Kelompok',
              active: true,
            },
          ],
        }
      }], 
      meta: {
        pageTitle: 'Edit Profil Kelompok',
        breadcrumb: [ 
          {
            text: 'Edit profil Kelompok',
            active: true,
          },
        ],
      },
    },
    {
      path: '/arsip_dokumen',
      name: '/arsip_dokumen',
      component: () => import('@/views/dinas/arsip_dokumen/Index.vue'),
      children: [
        {
          path: '/arsip_dokumen/list',
          name: '/arsip_dokumen/list',
          component: () => import('@/views/dinas/arsip_dokumen/AppList.vue'),
          meta: {
            pageTitle: 'Arsip Dokumen Dinas',
            breadcrumb: [ 
              {
                text: 'Arsip Dokumen Dinas ',
                active: true,
              }, 
            ],
          }
        }, {
        path: '/arsip_dokumen/detail',
        name: '/arsip_dokumen/detail',
        component: () => import('@/views/dinas/arsip_dokumen/detail/Index.vue'),
        meta: {
          pageTitle: 'Arsip Dokumen Dinas',
          breadcrumb: [ 
            {
              text: 'Arsip Dokumen Dinas',
              active: true,
            },
            {
              text: 'Detail Arsip Dokumen',
              active: true,
            },
          ],
        }
      }], 
      meta: {
        pageTitle: 'Arsip Dokumen Dinas',
        breadcrumb: [ 
          {
            text: 'Arsip Dokumen Dinas',
            active: true,
          },
        ],
      },
    },

    {
      path: '/log_transaksi',
      name: '/log_transaksi',
      component: () => import('@/views/dinas/log_transaksi/Index.vue'),
      meta: {
        pageTitle: 'Log  Transaksi',
        breadcrumb: [ 
          {
            text: 'Log Transaksi',
            active: true,
          },
        ],
      },
    }
]
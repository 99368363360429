export default[
    
    {
      path: '/profil_usaha',
      name: '/profil_usaha',
      component: () => import('@/views/profil_usaha/Index.vue'),
      meta: {
        pageTitle: 'Profil Usaha',
        breadcrumb: [ 
          {
            text: 'Profil Usaha',
            active: true,
          },
        ],
      },
    },  
    {
        path: '/pengajuan_rekom',
        name: '/pengajuan_rekom',
        component: () => import('@/views/pengajuan_rekom/Index.vue'),
        meta: {
          pageTitle: 'Pengajuan rekomendasi Pembelian BBM',
          breadcrumb: [ 
            {
              text: 'Pengajuan R,ekomendasi Pembelian BBM',
              active: true,
            },
          ],
        },
      }, 
]
export default {
    mamespaced : true,
    state: { 
        idFolder :"",
        rsFolder: [],
        folderDetail:[],
        rsFile :[],
        rsJenisKelompok:[]
    },
    mutations: { 
        SetIdFolder(state, value) {
            state.idFolder = value 
        }, 
        SetRsFolder(state, value) {
            state.rsFolder = value 
        },
        SetRsFile(state, value) {
            state.rsFile = value 
        },
        SetFolderDetail(state, value){
            state.folderDetail = value 
        },
        SetRsJenisKelompok(state, value){
            state.rsJenisKelompok = value 
        }
    },
    actions: {
   },
   
}

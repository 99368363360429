export default {
    mamespaced : true,
    state: { 
        rsProfilKelompok: [], 
        rsAnggota : [],
        rsDokumen : []
    },
    mutations: { 
        setRsProfilKelompok(state, value) {
            state.rsProfilKelompok = value 
        }, 
        setRsAnggota(state, value) {
            state.rsAnggota = value 
        }, 
        setRsDokumen(state, value) {
            state.rsDokumen = value 
        }, 
    },
    actions: {
   },
   
}

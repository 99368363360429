export default {
    mamespaced : true,
    state: { 
        rsData: [], 
        idBantuan : [],
        idKelompok : [],
        detailData : [],

        rsDataAdd: [], 
        idBantuanAdd : [],
        idKelompokAdd : [],
        detailDataAdd : []

    },
    mutations: { 
        SetRsData(state, value) {
            state.rsData = value 
        }, 
        SetIdBantuan(state, value) {
            state.idBantuan = value 
        }, 
        SetIdKelompok(state, value) {
            state.idKelompok = value 
        }, 
        SetDetailData(state, value){
            state.detailData = value 
        },
        SetRsDataAdd(state, value) {
            state.rsDataAdd = value 
        }, 
        SetIdBantuanAdd(state, value) {
            state.idBantuanAdd = value 
        }, 
        SetIdKelompokAdd(state, value) {
            state.idKelompokAdd = value 
        }, 
        SetDetailDataAdd(state, value){
            state.detailDataAdd = value 
        }


    },
    actions: {
   },
   
}

export default {
    mamespaced : true,
    state: {  
        rsData: [] ,
        idSTDK :"",
        idKelompok : "",
        detailData : {},
        noteData : []
    },
    mutations: { 
        SetRsData(state, value) {
            state.rsData = value 
        },
        SetIdSTDK(state, value) {
            state.idSTDK = value 
        },
        SetIdKelompok(state, value) {
            state.idKelompok = value 
        },
        SetDetailData(state, value) {
            state.detailData = value 
        },
        SetNoteData(state, value) {
            state.noteData = value 
        },
    },
    actions: {
   },
   
}



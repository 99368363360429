import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import aclUser from './acl/user';
import aclMenu from './acl/menu'; 
import aclLevel from './acl/level';
import stdkProfil from './stdk/profil_kelompok';
import stdkPengajuan from './stdk/pengajuan';
import stdkBantuan from './stdk/pengajuan_bantuan';
import dinasValidasi from './dinas/validasi';
import DinasValidasiSTDK from './dinas/validasi_stdk';
import DinasArsip from './dinas/arsip';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    aclUser,
    aclMenu,
    aclLevel,
    stdkProfil,
    stdkPengajuan,
    dinasValidasi,
    DinasValidasiSTDK,
    stdkBantuan,
    DinasArsip

  },
  strict: process.env.DEV,
})

export default {
    mamespaced : true,
    state: { 
        rsProfilKelompok: [], 
        rsAnggota : [],
        rsDokumen : [],
        rsSejarahPendirian : [],
        idKelompok :"",
        rsNote : []
    },
    mutations: { 
        setRsProfilKelompok(state, value) {
            state.rsProfilKelompok = value 
        }, 
        setRsAnggota(state, value) {
            state.rsAnggota = value 
        }, 
        setRsDokumen(state, value) {
            state.rsDokumen = value 
        }, 
        setRsSejarahPendirian(state, value){
            state.rsSejarahPendirian = value 
        },
        setIdKelompok(state, value){
            state.idKelompok = value 
        },
        setRsNote(state, value){
            state.rsNote = value 
        }

    },
    actions: {
   },
   
}
